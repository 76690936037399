<template>
  <div class="wameed-dashboard-page-content">
    <page-header
      :title="$t('personal_information.title')"
      :sub-title="$t('settings.subTitle_employee')"
    />
    <section class="wameed-dashboard-page-content_body">
      <b-row class="px-md-2 px-0 no-gutters">
        <b-col lg="12" md="12" class="px-0">
          <b-card
            no-body
            class="cursor-pointer wameed-card py-5 px-md-5 px-3"
            @click="changePassword = true"
          >
            <div
              class="
                d-flex
                py-1
                px-1
                align-items-center
                justify-content-between
              "
            >
              <h4 class="h-panel__title text-reg-20">
                {{ $t('common.change_password') }}
              </h4>
              <arrow-down-icon />
            </div>
          </b-card>
          <change-password
            :visible="changePassword"
            @close="changePassword = false"
          />
        </b-col>
        <b-col lg="12" md="12" class="px-0">
          <b-card no-body class="cursor-pointer wameed-card">
            <b-card-header class="align-items-start tab-title">
              <h5 class="text-reg-18 text-font-main">
                {{ $t('personal_information.title') }}
              </h5>
            </b-card-header>
            <wameed-form refs="" @submitAction="submitUpdateProfile">
              <template slot="form-content" slot-scope="{ invalid }">
                <b-card-body
                  class="
                    position-relative
                    py-0
                    justify-content-between
                    px-md-5 px-0
                  "
                >
                  <!-- form -->
                  <div class="d-flex flex-wrap pt-4">
                    <b-col cols="12" md="6">
                      <text-input
                        v-model="personalInformation.name"
                        :label="$t('personal_information.name')"
                        input-classes="text-med-14 text-font-main"
                        name="name"
                        rules="required"
                        placeholder="----------------"
                      />
                    </b-col>
                    <b-col cols="12" md="6">
                      <text-input
                        v-model="personalInformation.email"
                        :label="$t('personal_information.email')"
                        input-classes="text-med-14 text-font-main"
                        name="email"
                        rules="required"
                        placeholder="----------------"
                      />
                    </b-col>
                    <b-col cols="12" md="6">
                      <wameed-dropdown
                        v-if="getCountries"
                        id="country"
                        v-model="personalInformation.country"
                        :label="$t('personal_information.country')"
                        placeholder="----------------"
                        classes="text-medium-16 w-100"
                        value-classes=" text-med-14 text-font-main"
                        title="name"
                        variant="disable"
                        :items="getCountries"
                        :search="true"
                        text="name"
                        name="country"
                        rules="required"
                        @input="changeCities()"
                      />
                    </b-col>
                    <b-col cols="12" md="6">
                      <phone-number-input
                        v-model="personalInformation.phone"
                        :label="$t('personal_information.phone')"
                        input-classes="text-med-14 text-font-main"
                        name="phone"
                        rules="required|length:9"
                        @update="results = $event"
                      />
                    </b-col>
                    <b-col cols="12" md="6">
                      <wameed-dropdown
                        id="city"
                        v-model="personalInformation.city"
                        :label="$t('personal_information.city')"
                        placeholder="----------------"
                        classes="text-medium-16 w-100"
                        value-classes="text-med-14 text-font-main"
                        title="name"
                        variant="disable"
                        :items="getCitiesItems"
                        :search="true"
                        text="name"
                        name="city"
                        rules="required"
                      />
                    </b-col>
                    <b-col md="12">
                      <multi-images-upload
                        id="file"
                        :max="1"
                        clear-all=""
                        :label="$t('users_data.photo')"
                        name="file"
                        rules=""
                        file-type="image"
                        :imgs="
                          personalInformation.photo
                            ? personalInformation.photo
                            : []
                        "
                        @changed="updateImages"
                      />
                    </b-col>
                  </div>
                </b-card-body>
                <b-card-footer class="px-4 py-3">
                  <hr />
                  <div class="d-flex justify-content-between">
                    <div class="px-4 py-3">
                      <wameed-btn
                        classes="  text-med-14 text-white rounded-10"
                        :title="$t('common.save_edited')"
                        :disabled="invalid"
                        type="submit"
                        variant="main"
                      />
                    </div>
                    <div class="px-4 py-3">
                      <wameed-btn
                        classes="  text-med-14 text-font-secondary rounded-10 "
                        :title="$t('common.cancel')"
                        type="button"
                        variant="gray"
                        onclick="history.back()"
                      />
                    </div>
                  </div>
                </b-card-footer>
              </template>
            </wameed-form>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WameedForm from '@/components/WameedForm.vue';
import WameedBtn from '@/components/WameedBtn.vue';
import WameedDropdown from '@/components/WameedDropdown.vue';
import WameedAsideModal from '@/components/WameedAsideModal.vue';
import WameedSwitch from '@/components/WameedSwitch.vue';
import TextEditor from '@/components/TextEditor.vue';
import DetailsForm from '@/components/pages/setting/company-information/DetailsForm.vue';
import SocialMediaForm from '@/components/pages/setting/company-information/SocialMediaForm.vue';
import PageHeader from '@/components/PageHeader.vue';
import ChangePassword from '@/components/pages/setting/company-information/ChangePassword.vue';
import TextInput from '@/components/TextInput.vue';
import TextArea from '@/components/TextArea.vue';
import MultiImagesUpload from '@/components/multiImagesUpload.vue';
import PhoneNumberInput from '@/components/PhoneNumberInput.vue';

export default {
  components: {
    WameedBtn,
    WameedDropdown,
    WameedAsideModal,
    WameedSwitch,
    WameedForm,
    TextEditor,
    DetailsForm,
    SocialMediaForm,
    PageHeader,
    ChangePassword,
    TextInput,
    TextArea,
    MultiImagesUpload,
    PhoneNumberInput,
  },
  data() {
    return {
      changePassword: false,
      uploadLogo: null,
      serviceProviderName: null,
      serviceProviderType: null,
      email: null,
      city: null,
      getCitiesItems: [],
      personalInformation: {
        name: null,
        city: null,
        country: null,
        phone: null,
        email: null,
        photo: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      getProfileInfo: 'getProfileInfo',
      getCountries: 'getCountries',
      getCities: 'getCities',
    }),
  },
  watch: {
    // getProfileInfo(data) {
    //   this.personalInformation.name = data.name;
    //   this.personalInformation.city = data.city;
    //   this.personalInformation.country = data.country;
    //   this.personalInformation.phone = data.phone;
    //   this.personalInformation.email = data.email;
    //   this.personalInformation.photo.push(data.photo);
    // },
    getCountries(data) {
      if (this.personalInformation.country) {
        this.loadCities(this.personalInformation.country.id);
      }
    },
    getCities(data) {
      this.getCitiesItems = data;
    },
  },
  created() {
    this.watchGetProfileInfo();
    this.loadCountries();
  },
  methods: {
    ...mapActions({
      loadProfileInfo: 'loadProfileInfo',
      updateProfile: 'updateProfile',
      uploadFiles: 'uploadFiles',
      loadCountries: 'loadCountries',
      loadCities: 'loadCities',
    }),
    watchGetProfileInfo() {
      this.personalInformation.name = this.getProfileInfo.name;
      this.personalInformation.country = JSON.parse(
        this.getProfileInfo.country
      );
      if (this.getProfileInfo.country) {
        this.loadCities(this.getProfileInfo.country.id);
      }
      this.personalInformation.city = JSON.parse(this.getProfileInfo.city);
      this.personalInformation.phone = this.getProfileInfo.phone;
      this.personalInformation.email = this.getProfileInfo.email;
      if (this.getProfileInfo.photo) {
        this.personalInformation.photo.push(this.getProfileInfo.photo);
      }
    },
    cancelUpdate() {
      this.$router.push({
        name: 'dashboard',
        params: { lang: this.$i18n.locale },
      });
    },
    uploadImageAction(event, type = 'upload') {
      let files = [];
      if (type === 'upload') {
        files = event.currentTarget.files;
      } else {
        files = event;
      }
      if (!files.length) return;
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('file', files[i]);
        this.uploadFiles(formData).then((response) => {
          this.personalInformation.work_proof = response.content.file_name;
        });
      }
    },
    uploadImageActionCv(event, type = 'upload') {
      let files = [];
      if (type === 'upload') {
        files = event.currentTarget.files;
      } else {
        files = event;
      }
      if (!files.length) return;
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('file', files[i]);
        this.uploadFiles(formData).then((response) => {
          this.personalInformation.cv = response.content.file_name;
        });
      }
    },
    updateImages(images) {
      this.personalInformation.photo = images;
    },
    submitUpdateProfile() {
      this.updateProfile({
        name: this.personalInformation.name,
        city: this.personalInformation.city,
        country: this.personalInformation.country,
        phone: this.personalInformation.phone,
        email: this.personalInformation.email,
        photo:
          this.personalInformation.photo &&
          this.personalInformation.photo.length > 0
            ? this.personalInformation.photo[0].file_name
            : null,
      }).then((response) => {
        this.loadProfileInfo();
      });
    },
    changeCities() {
      this.personalInformation.city = null;
      if (this.personalInformation.country) {
        this.loadCities(this.personalInformation.country.id);
      }
    },
  },
};
</script>
