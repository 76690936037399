<template>
  <b-form-group
    class="wameed-input text-reg-14"
    :class="fieldClasses"
    :label-for="id"
  >
    <label
      v-if="label"
      :class="rules && rules.split('|').includes('required') ? 'required' : ''"
      class="text-font-main"
      :for="id"
    >{{ label }}</label>
    <span
      v-if="subLable"
      class="text-reg-14 text-icon"
    >{{ subLable }}</span>
    <validation-provider
      #default="{ errors }"
      :name="name"
      :vid="name"
      :rules="rules"
    >
      <!-- overflow-hidden -->
      <b-input-group
        class="input-group-merge "
        :class="errors.length > 0 ? 'is-invalid' : null"
      >
        <vue-phone-number-input
          :id="id"
          :value="value"
          dir="ltr"
          class="form-control text-bold-14 text-font-main"
          no-example
          no-flags
          default-country-code="SA"
          show-code-on-list
          color="#4C535F"
          :translations="usertranslations"
          :state="errors.length > 0 ? false : null"
          @input="updateValue($event)"
          @update="$emit('update', $event)"
        />
      </b-input-group>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider } from "vee-validate";
import { required, email } from "@validations";

export default {
  components: {
    ValidationProvider,
  },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    isAppend: {
      type: Boolean,
      default: null,
    },
    appendClass: {
      type: String,
      default: null,
    },
    appendText: {
      type: String,
      default: null,
    },
    prepenIcon: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    subLable: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    fieldClasses: {
      type: String,
      default: "",
    },
    inputClasses: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "text",
    },
    rules: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      // validation rules
      required,
      email,
      usertranslations: {
        countrySelectorLabel: null,
        phoneNumberLabel: "--- --- ----",
        countrySelectorError: "Choisir un pays",
      },
    };
  },
  methods: {
    iconClick() {
      this.$emit("iconClick");
    },
    prepenIconClick() {
      this.$emit("prepenIconClick");
    },
    updateValue(value) {
      this.$emit("input", value);
    },
  },
};
</script>
